isDoubleClicked = (element) ->
	# if already clicked return TRUE to indicate this click is not allowed
	return true if element.data("isclicked")

	# mark as clicked for 1 second
	element.data "isclicked", true
	setTimeout ->
		element.removeData "isclicked"
	, 1000

	# return FALSE to indicate this click was allowed
	return false

$(
	# Submit form and save the area
	$('#addAreaSave').click( ->
		console.log('Submitting new area')
		$('#addArea').submit() if $('#addArea')[0].reportValidity()
	)
	# Submit form and update the area
	$('#updateAreaSave').click( ->
		console.log('Updating area')
		$('#updateArea').submit() if $('#updateArea')[0].reportValidity()
	)

	$('#batchIdUnlink').click( ->
		console.log('Unlinking batch')
		$('#editFormBatchId').val("")
		$('#batchLinkInfo').html("Batch unlinked, please use Update to apply changes, or Cancel to undo.  By unlinking this area from a batch, it will no longer be removed automatically if the batch is deleted.")
	)

	# Submit form and save the brand
	$('#addBrandSave').click( ->
		console.log('Submitting new brand')
		$('#addBrand').submit()
	)
	# Submit form and update the brand
	$('#updateBrandSave').click( ->
		console.log('Updating brand')
		$('#updateBrand').submit()
	)

	# Submit form and save the domain
	$('#addDomainAccountSave').click( ->
		$('#addDomainAccount').submit()
	)
	# Submit form and update the domain
	$('#updateDomainAccountSave').click( ->
		$('#updateDomainAccount').submit()
	)

	#Submit form and save the event
	$('#addEventSave').click( ->
		console.log('Submitting a new event')
		validateEvent($('#addEvent')[0])
		$('#addEvent').submit() if $('#addEvent').valid()
	)
	#Submit form and save the event
	$('#updateEventSave').click( ->
		console.log('Updating event')
		validateEvent($('#updateEvent')[0])
		$('#updateEvent').submit() if $('#updateEvent').valid()
	)

	$('#updateEvent ul#eventModifyTabs a[data-toggle="tab"]').on('shown.bs.tab', (e) ->
		isUserDefined = e.target.toString().indexOf('customevent') > 0
		$('#updateEvent #isUserDefined').val(isUserDefined.toString())
	)

	$('#addEvent ul#eventModifyTabs a[data-toggle="tab"]').on('shown.bs.tab', (e) ->
		isUserDefined = e.target.toString().indexOf('customevent') > 0
		$('#addEvent #isUserDefined').val(isUserDefined.toString())
	)

	validateEvent = (form) ->
		isUserDefined = form.elements['isUserDefined'].value == "true"
		if isUserDefined
			form.elements['label'].setAttribute("required", "true");
		else 
			form.elements['label'].removeAttribute("required", "true");

	# Submit form and save the Account
	$('#addAccountSave').click( ->
		console.log('Submitting new Account')
		$('#addAccount').submit()
	)
	# Submit form and update the Account
	$('#updateAccountSave').click( ->
		console.log('Updating Account')
		$('#updateAccount').submit()
	)
	$('#updateAccountPermissionsSave').click( ->
		console.log('Updating Account Permissions')
		$('#editAccountPermissions').submit()
	)
	$('#inviteUserSave').click( ->
		console.log('Inviting user')
		$('#inviteUserError').html('')
		if $('input[name="permissions[]"]:checked').length > 0
			$('#inviteUser').submit() if $('#inviteUser')[0].reportValidity()
		else 
			$('#inviteUserError').html('Please select at least one permission.')
	)
	# Audit Log behaviour
	$('#filterLog').click( ->
		filter = $('#logFilter').find(":selected").map(-> @value).get().join("%2C")
		originFilter = $('#logOriginFilter').find(":selected").map(-> @value).get().join("%2C")
		idFilter = $('#logFilterId').val()
		window.location.href = "/portal/logs?f=#{filter}&o=#{originFilter}&id=#{idFilter}"
	)
	$('#filterLogMap').click( ->
		filter = $('#logFilter').find(":selected").map(-> @value).get().join("%2C")
		originFilter = $('#logOriginFilter').find(":selected").map(-> @value).get().join("%2C")
		from = $('#fromDate').val()
		to = $('#toDate').val()
		window.location.href = "/portal/logs/map?f=#{filter}&o=#{originFilter}&from=#{from}&to=#{to}&filtered=true"
	)
	$('#filterLogSummary').click( ->
		filter = $('#logFilter').find(":selected").map(-> @value).get().join("%2C")
		originFilter = $('#logOriginFilter').find(":selected").map(-> @value).get().join("%2C")
		from = $('#fromDate').val()
		to = $('#toDate').val()
		window.location.href = "/portal/logs/summary?f=#{filter}&o=#{originFilter}&from=#{from}&to=#{to}&filtered=true"
	)

	#Notifications
	$('#sendNotificationCancel').click( ->
		$('#sendNotificationModal').modal('hide')
	)

	$('a[name=composeNotification]').click( ->
		$('#statusMessage').html('');
		$('#sendNotificationSave').removeAttr('disabled')
		$('#deviceKey').val($(this).attr('deviceKey'))
		$('#sendNotificationModal').modal('show')
	)

	$('#sendNotificationSave').click( ->
		return false if isDoubleClicked($(this))
		console.log('Sending notifification')
		$('#statusMessage').html('');
		form = $('#sendNotification')
		return if !form.valid()
		$.ajax({
			type: "POST"
			url: form.attr('action')
			data: form.serialize()
			success: (res) ->
				$('#sendNotificationSave').attr('disabled','disabled')
				$('#statusMessage').html(res);
			error: (res) -> $('#statusMessage').html('Error - ' + res.responseText);
			traditional: true
		})
	)

	$('#sendNotificationBroadcastSubmit').click( ->
		return false if isDoubleClicked($(this))
		console.log('Sending notifification broadcast')
		$('#statusMessage').html('');
		form = $('#sendNotificationBroadcast')
		return if !form.valid()
		$.ajax({
			type: "POST"
			url: form.attr('action')
			data: form.serialize()
			success: (res) -> $('#statusMessage').html(res);
			error: (res) -> $('#statusMessage').html('Error - ' + res.responseText);
			traditional: true
		})
	)

	$('#sendNotificationByAreaSubmit').click( ->
		return false if isDoubleClicked($(this))
		console.log('Sending notifification device broadcast')
		$('#statusMessage').html('');
		form = $('#sendNotificationDeviceBroadcast')
		return if !form.valid()
		$.ajax({
			type: "POST"
			url: form.attr('action')
			data: form.serialize()
			success: (res) -> $('#statusMessage').html(res);
			error: (res) -> $('#statusMessage').html('Error - ' + res.responseText);
			traditional: true
		})
	)

	#Notification Validation forms
	$('#sendNotification').validate( {
		errorClass: "help-inline"
	})

	$('#sendNotificationBroadcast').validate( {
		errorClass: "help-inline",
		rules: {
			token: { pattern: "^broadcast|Broadcast|BROADCAST$", required: true }
		}
	})

	$('#sendNotificationDeviceBroadcast').validate( {
		errorClass: "help-inline",
		rules: {
			token: { pattern: "^broadcast|Broadcast|BROADCAST$", required: true }
		}
	})

	$('#updateNotificationTimeSave').click( ->
		$('#changeNotificationTimeForm').submit() if $('#changeNotificationTimeForm').valid()
	)

	#Test location feature
	$('a[name=testLocation]').click( (e) ->
		console.log('Testing location')
		form = $('#' + $(this).attr("form"))
		$.ajax({
			type: "POST"
			url: form.attr('action')
			data: form.serialize()
			success: (res) ->
				$('#service-result').html(res)
				$('#locationTestModal').modal('show')
			error: (res) ->
				$('#service-result').html('Error - ' + res.responseText);
				$('#locationTestModal').modal('show')
			traditional: true
		})		
	)

	$('#locationTestClose').click( ->
		$('#locationTestModal').modal('hide')
	)

	#Automatically show edit modal boxes
	$('#editAreaModal').modal('show')
	$('#editEventModal').modal('show')
	$('#editBrandModal').modal('show')
	$('#editDomainModal').modal('show')
	$('#editAccountModal').modal('show')
	$('#editAccountPermissionsModal').modal('show')
	$('#changeNotificationTimeModal').modal('show')

	$('#saveGeofence').click(geofenceMap.saveGeofence) if geofenceMap?
	$('#saveGeofenceDuplicate').click(geofenceMap.saveGeofenceDuplicate) if geofenceMap?
	$('#clearGeofence').click(geofenceMap.clearGeofence) if geofenceMap?
	$('#importGeofence').click( ->
		$('#importGeoJsonField').val('')
		$('#importGeofencefromGeoJsonError').text('')
		$('#addGeoJsonModal').modal('show') if geofenceMap?
	)
	$('#viewGeoJsonModal').click( ->
		$('#viewGeoJsonField').text("Nothing to show, use the draw polygon tool.") if !geofenceMap?.heldPolygon
		$('#viewGeoJsonField').text(geofenceMap.getHeldPolygonGeoJson()) if geofenceMap?.heldPolygon
		$('#geoJsonModal').modal('show')
	)
	$('#addNewGeofence').click( ->
		geofenceMap.saveViewport() if geofenceMap?
	)
	$('#importGeofencefromGeoJson').click(geofenceMap.parseGeoJson) if geofenceMap?
	

	dateOnlyConfig = {
		"allowInputToggle": true,
		"showClose": true,
		"showClear": true,
		"showTodayButton": true,
		"format": "YYYY-MM-DD"
	}

	$('.selectpicker').selectpicker()
	$('#addEvent #startdate').datetimepicker(dateOnlyConfig)
	$('#addEvent #finishdate').datetimepicker(dateOnlyConfig)
	$('#updateEvent #startdate').datetimepicker(dateOnlyConfig)
	$('#updateEvent #finishdate').datetimepicker(dateOnlyConfig)
	$('#batchUploadPart2 #startDate').datetimepicker(dateOnlyConfig)
	$('#batchUploadPart2 #endDate').datetimepicker(dateOnlyConfig)
	
	$('#notification_schedule #schedule').datetimepicker({
		"allowInputToggle": true,
		"showClose": true,
		"showClear": true,
		"showTodayButton": true,
		"format": "YYYY-MM-DD HH:mm:ss Z" ,
	})
	$('#changeNotificationTimeForm #schedule').datetimepicker({
		"allowInputToggle": true,
		"showClose": true,
		"showClear": true,
		"showTodayButton": true,
		"format": "YYYY-MM-DD HH:mm:ss Z" ,
	})

	$(":radio[name='sendMode']").change( -> 
		category = this.value
		$("#notification_schedule [name='schedule']").prop('disabled', category == 'immediate')
		$("#notification_schedule [name='schedule']").prop('required', category != 'immediate')
	)
	

	$('#saveBoundingBox').click(boundingBoxMap.saveBoundingBox) if boundingBoxMap?
	$('#removeBoundingBox').click(boundingBoxMap.removeBoundingBox) if boundingBoxMap?

	$('#findNotificationDevicesByPolygon').click(geofenceMap.findDevices) if geofenceMap?
	$('#findNotificationDevicesBySuburb').click(geofenceMap.findDevicesBySuburb) if geofenceMap?
	$('#findNotificationDevicesByRegion').click(geofenceMap.findDevicesByRegion) if geofenceMap?

	#Login Form
	loginAction = ->
		form = $('#signInForm')
		return if !form.valid()
		if !!$('#form-password-input').val()
			form.submit()
			return
		$.ajax({
			type: "POST"
			url: '/portal/login-check'
			data: form.serialize()
			success: (res) ->
				jres = JSON.parse(res)
				if jres.data.isExternal
					$('#form-password-input').hide()
					$('#form-signin-error').empty()
					window.location.href = jres.data.redirect 
				else
					$('#form-password-input').fadeIn()
					$('#form-signin-error').empty()
					$('#form-password-input').focus()
			error: (res) ->
				$('#service-result').html('Error - ' + res.responseText);
				$('#locationTestModal').modal('show')
			traditional: true
		})		

	$('#form-login-submit').click( (e) ->
		loginAction()
	)

	$('#form-password-input').keypress( (e) ->
		$('#form-signin-error').empty()
	)

	$('#form-email-input').keyup( (e) ->
		loginAction() if $("#form-email-input").is(":focus") && event.key == "Enter"
	)

	$('#form-password-input').keyup( (e) ->
		loginAction() if $("#form-password-input").is(":focus") && event.key == "Enter"
	)

	$('#batchSkipStep').click( (e) ->
		$('.nav-tabs a[href="#batchUploadPart2"]').tab('show');
	)


)
